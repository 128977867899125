import React from "react"
import cn from "classnames"
import * as styles from "./section.module.scss"

export default function Section({
  children,
  className,
  highlight = false,
  ...props
}) {
  return (
    <section
      className={cn(
        styles.section,
        highlight ? styles.highlight : null,
        className
      )}
      {...props}
    >
      {children}
    </section>
  )
}
