import React from "react"
import Seo from "../../../components/seo"
import Layout from "../../../components/layout"
import Title from "../../../components/title"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Section from "../../../components/section"
import Button from "../../../components/button"
import Grid from "../../../components/grid"
import Technologies from "../../../components/technologies"
import Workflow from "../../../components/workflow"
import * as styles from "./user-interface-development.module.scss"

export default function UserInterfaceDevelopmentPage({ data }) {
  return (
    <>
      <Seo title="User Interface Development" />
      <Layout>
        <section className={styles.ui}>
          <header>
            <Title headingLevel="h4"></Title>
            <Title headingLevel="h1">UI/UX Development</Title>
            <Title headingLevel="p">
              Integrating UX/UI designs and wireframes into a user-friendly,
              mobile-first, accessible, and intuitive interface
            </Title>
          </header>
          <div className={styles.jamstackHero}>
            <GatsbyImage image={data.ui.sharp.gatsbyImageData} alt="" />
          </div>
        </section>

        <Section highlight>
          <Grid className={styles.benefits}>
            <header>
              <Title headingLevel="h4">❤️</Title>
              <Title headingLevel="h2">
                Developing user interfaces with love
              </Title>
            </header>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.mobileFirst.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Mobile-first</Title>
              <p>
                Since over 80% of internet users use mobile devices to surf the
                web we use a mobile-first approach for a better user experience
                on mobile devices
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.seo.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Seo Friendly</Title>
              <p>
                We use semantic html codes in an appropriate manner, such as
                giving textual explanations to photos, descriptive titles and
                descriptions, and so on.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.accessibility.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Accessibility in mind</Title>
              <p>
                Accessibility refers to ensuring that your website or app is
                accessible (i.e. usable) by all users, regardless of how they
                visit your website or app. We design our code with accessibility
                in mind.
              </p>
            </div>

            <div className={styles.benefit}>
              <GatsbyImage
                image={data.performance.sharp.gatsbyImageData}
                style={{ width: 170 }}
                alt=""
              />
              <Title headingLevel="h3">Excellent performance</Title>
              <p>
                Website performance is what makes or breaks businesses nowadays.
                We optimize our code for great user experience and performance
                on the web.
              </p>
            </div>
          </Grid>
        </Section>

        <Technologies />

        <Section highlight className={styles.otherServices}>
          <Title headingLevel="h4">Looking for other services?</Title>
          <Title headingLevel="h2">JAMstack Marketing Websites</Title>
          <Title headingLevel="p">
            Raise your website’s visibility and get more customers for your
            business with Seo-friendly websites.
          </Title>
          <Button to="/services/jamstack-website">Read more</Button>
        </Section>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    ui: file(relativePath: { eq: "user-interface.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    accessibility: file(relativePath: { eq: "accessibility.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    performance: file(relativePath: { eq: "performance.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    seo: file(relativePath: { eq: "seo.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    mobileFirst: file(relativePath: { eq: "mobile-first.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
