import React from "react"
import Title from "../title"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import cn from "classnames"
import * as styles from "./workflow.module.scss"

export default function Workflow({ className, highlight = false }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section
          className={cn(
            styles.workflow,
            className,
            highlight ? styles.highlight : null
          )}
        >
          <header className={styles.header}>
            <Title headingLevel="h4">Workflow</Title>
            <Title headingLevel="h2">Get your project a pro service</Title>
          </header>
          <div className={styles.content}>
            <div className={styles.service}>
              <GatsbyImage
                image={data.goal.sharp.gatsbyImageData}
                style={{ width: 220 }}
                alt=""
              />
              <Title headingLevel="h3">
                Settling up for the project’s goals
              </Title>
              <p>
                You want your web product to hit the highest performance scores?
                Be as secure as it possibly can? Or you want to scale it to
                multiple languages in a blink of an eye? We got your back! We’ll
                get to know what’s most important for your success and have it
                in mind when choosing the best tech set.
              </p>
            </div>

            <div className={styles.service}>
              <GatsbyImage
                image={data.mindmap.sharp.gatsbyImageData}
                style={{ width: 220 }}
                alt=""
              />
              <Title headingLevel="h3">
                Picking the right tools <br />
                for the job
              </Title>
              <p>
                Once we understand your project wants and needs it’s time to
                swim through the sea of technologies and choose the best for
                your project. We’ll propose you a couple of options with their
                possible upsides and downsides. We leave the final decision to
                you, but we’ll always be close to help with advice.
              </p>
            </div>

            <div className={styles.service}>
              <GatsbyImage
                image={data.environment.sharp.gatsbyImageData}
                style={{ width: 220 }}
                alt=""
              />
              <Title headingLevel="h3">Setting up a work environment</Title>
              <p>
                When you give us the thumbs up, we’ll set up the project
                management environment to keep you in the loop as we work on the
                project. You’ll get an access to our Slack workspace, Asana,
                Github repository, and all other tools used during the
                development stage. You also get to choose how often you’d like
                to receive project updates from us and in what form should they
                be in.
              </p>
            </div>

            <div className={styles.service}>
              <GatsbyImage
                image={data.board.sharp.gatsbyImageData}
                style={{ width: 220 }}
                alt=""
              />
              <Title headingLevel="h3">Transparent project management</Title>
              <p>
                Transparency is our core value and it mirrors in our practice.
                Throughout the project you’ll be able to access the code
                repository we’re working on and see what we’ve put together in
                real time. You’ll also have access to our time tracking app for
                your project to see how much time each task consumed.
              </p>
            </div>

            <div className={styles.service}>
              <GatsbyImage
                image={data.contact.sharp.gatsbyImageData}
                style={{ width: 220 }}
                alt=""
              />
              <Title headingLevel="h3">No need to say goodbye</Title>
              <p>
                Rarely we see our work ending with the deployment. In most
                cases, we stay with our clients for a long time after developing
                the product. We handle their additional feature requests and
                ongoing web architecture maintenance, so they could focus on
                growing their business.
              </p>
            </div>
          </div>
        </section>
      )}
    />
  )
}

export const query = graphql`
  {
    goal: file(relativePath: { eq: "goal.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    mindmap: file(relativePath: { eq: "mindmap.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    environment: file(relativePath: { eq: "environment.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    board: file(relativePath: { eq: "board.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    contact: file(relativePath: { eq: "contact.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
