import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Title from "../title"
import { GatsbyImage } from "gatsby-plugin-image"
import cn from "classnames"
import * as styles from "./technologies.module.scss"

export default function Technologies({ className }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={cn(styles.technologies, className)}>
          <header className={styles.header}>
            <Title headingLevel="h4">Technologies we use</Title>
            <Title headingLevel="h2">Beautifying the web</Title>
            <Title headingLevel="p">
              Every project is a new story, yet some things stay the same. We
              pick a dedicated set of tools for each one, for the best
              performance possible.
            </Title>
          </header>
          <div className={styles.content}>
            <Title headingLevel="h4">Essentials</Title>
            <div className={styles.images}>
              <GatsbyImage
                image={data.html.sharp.gatsbyImageData}
                style={{ maxWidth: 45 }}
                alt="HTML 5"
                title="HTML 5"
              />
              <GatsbyImage
                image={data.css.sharp.gatsbyImageData}
                style={{ maxWidth: 45 }}
                alt="CSS"
                title="CSS"
              />
              <GatsbyImage
                image={data.js.sharp.gatsbyImageData}
                style={{ maxWidth: 65 }}
                alt="JavaScript"
                title="JavaScript"
              />
              <GatsbyImage
                image={data.react.sharp.gatsbyImageData}
                style={{ maxWidth: 60 }}
                alt="React.js"
                title="React.js"
              />
              <GatsbyImage
                image={data.angular.sharp.gatsbyImageData}
                style={{ maxWidth: 75 }}
                alt="Angular"
                title="Angular"
              />

              <GatsbyImage
                image={data.vue.sharp.gatsbyImageData}
                style={{ maxWidth: 60 }}
                alt="Vue.js"
                title="Vue.js"
              />
            </div>
          </div>
          <div className={styles.content}>
            <Title headingLevel="h4">Data Sources</Title>
            <div className={styles.images}>
              <GatsbyImage
                image={data.contentful.sharp.gatsbyImageData}
                style={{ maxWidth: 160 }}
                alt="Contentful"
              />
              <GatsbyImage
                image={data.storyblok.sharp.gatsbyImageData}
                style={{ maxWidth: 160 }}
                alt="Storyblok"
              />
              <GatsbyImage
                image={data.sanity.sharp.gatsbyImageData}
                style={{ maxWidth: 110 }}
                alt="Sanity"
              />
              <GatsbyImage
                image={data.forestry.sharp.gatsbyImageData}
                style={{ maxWidth: 160 }}
                alt="Forestry"
              />
              <GatsbyImage
                image={data.dato.sharp.gatsbyImageData}
                style={{ maxWidth: 120 }}
                alt="Dato"
              />
            </div>
          </div>
          <div className={styles.content}>
            <Title headingLevel="h4">Build</Title>
            <div className={styles.images}>
              <GatsbyImage
                image={data.gatsby.sharp.gatsbyImageData}
                style={{ maxWidth: 160 }}
                alt="Gatsby.js"
              />
              <GatsbyImage
                image={data.nextjs.sharp.gatsbyImageData}
                style={{ maxWidth: 120 }}
                alt="Next.js"
              />
              <GatsbyImage
                image={data.hugo.sharp.gatsbyImageData}
                style={{ maxWidth: 150 }}
                alt="Hugo"
              />
              <GatsbyImage
                image={data.react.sharp.gatsbyImageData}
                style={{ maxWidth: 60 }}
                alt="React"
              />
              <GatsbyImage
                image={data.nodejs.sharp.gatsbyImageData}
                style={{ maxWidth: 120 }}
                alt="Node.js"
              />
            </div>
          </div>
          <div className={styles.content}>
            <Title headingLevel="h4">Deploy & CDN</Title>
            <div className={styles.images}>
              <GatsbyImage
                image={data.netlify.sharp.gatsbyImageData}
                style={{ maxWidth: 130 }}
                alt="Netlify"
              />
              <GatsbyImage
                image={data.vercel.sharp.gatsbyImageData}
                style={{ maxWidth: 50 }}
                alt="Vercel"
              />
              <GatsbyImage
                image={data.firebase.sharp.gatsbyImageData}
                style={{ maxWidth: 150 }}
                alt="Firebase"
              />
              <GatsbyImage
                image={data.aws.sharp.gatsbyImageData}
                style={{ maxWidth: 70 }}
                alt="Aws"
              />
            </div>
          </div>
        </section>
      )}
    />
  )
}

export const query = graphql`
  {
    contentful: file(relativePath: { eq: "data-sources/contentful.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    storyblok: file(relativePath: { eq: "data-sources/storyblok.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    sanity: file(relativePath: { eq: "data-sources/sanity.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    forestry: file(relativePath: { eq: "data-sources/forestry.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    dato: file(relativePath: { eq: "data-sources/dato.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    gatsby: file(relativePath: { eq: "build/gatsby.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nextjs: file(relativePath: { eq: "build/nextjs.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    hugo: file(relativePath: { eq: "build/hugo.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    react: file(relativePath: { eq: "build/react.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    nodejs: file(relativePath: { eq: "build/nodejs.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    netlify: file(relativePath: { eq: "deploy/netlify.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    vercel: file(relativePath: { eq: "deploy/vercel.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    firebase: file(relativePath: { eq: "deploy/firebase.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    aws: file(relativePath: { eq: "deploy/aws.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    angular: file(relativePath: { eq: "angular.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    html: file(relativePath: { eq: "html.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    css: file(relativePath: { eq: "css.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    vue: file(relativePath: { eq: "vue.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    js: file(relativePath: { eq: "js.png" }) {
      sharp: childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
